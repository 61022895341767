import { extractNumericFromGID } from 'hooks/useShopifyData'

const basePayload = {
  SourceProductNumber: '',
  SourceProductCategoryNumber: '',
}

const fireAnalyticsEvent = payload => {
  window.dataLayer?.push(payload)
}

// Elevar is a third party analytics tool that we use to track user behaviour
const fireElevarAnalyticsEvent = payload => {
  // don't push to dataLayer as it will not be picked up by Elevar according to their documentation
  window.ElevarPushToDataLayer(payload)
}

const fireElevarAnalyticsEventGeneric = (eventName, payloadCore) => {
  const cartTotal = localStorage.getItem('cartTotal')

  const customerEmail =
    document.cookie.replace(/(?:(?:^|.*;\s*)_visitor_email\s*\=\s*([^;]*).*$)|^.*$/, '$1') || ''

  // User Properties Object
  const userProperties = {
    // The majority of this information can only be retrieved for a logged in user

    // The following fields are required
    user_consent: '', // Use an empty string
    visitor_type: customerEmail ? 'logged_in' : 'guest', // guest or logged_in

    // The following fields aren't required if unavailable
    customer_address_1: '',
    customer_address_2: '',
    customer_city: '',
    customer_country: '',
    customer_country_code: '',
    customer_email: customerEmail,
    customer_first_name: '',
    customer_id: '',
    customer_last_name: '',
    customer_order_count: '',
    customer_phone: '',
    customer_province: '',
    customer_province_code: '',
    customer_tags: '',
    customer_total_spent: '',
    customer_zip: '',
  }

  // This wrapper will encapsulate the payloadCore in the Elevar format
  var elevarPayload = {
    event: eventName || '',

    cart_total: cartTotal?.toString() || '0', // required only for dl_user_data

    user_properties: userProperties,
    ecommerce: {
      currencyCode: 'USD',
      ...payloadCore, // this is the payload that is specific to the event
    },
  }

  // console.log(`@Elevar/headless - ${eventName}`, elevarPayload)

  fireElevarAnalyticsEvent(elevarPayload)
}

const analyticsPayload = ({ eventName, data }) => {
  if (!eventName) {
    return
  }

  return {
    event: eventName,
    ...basePayload,
    ...(data || []).reduce((obj, prop) => ({ ...obj, [prop.name]: prop.value }), {}),
  }
}

const setCustomerEmail = email => {
  document.cookie = `_visitor_email=${email}; path=/; max-age=157680000;`
}

const fireCartUpdatedEvent = cart => {
  if (!cart.lineItems) {
    return
  }

  fireAnalyticsEvent({ owynCart: undefined })
  fireAnalyticsEvent({
    event: 'owynCartUpdated',
    owynCart: {
      products: cart.lineItems.map(mapCartLineItemToEcommerceProduct),
    },
  })
}

const changeImageDimension = (imgUrl, dimension = 'medium') => {
  let splittedArray = imgUrl.split('_')
  splittedArray[splittedArray.length - 1] = splittedArray[splittedArray.length - 1].replace(
    'small',
    dimension
  )
  return splittedArray.join('_')
}

const mapCartLineItemToEcommerceProduct = lineItem => {
  const imageUrl = changeImageDimension(
    lineItem.image.match(new RegExp('^//')) ? 'https:' + lineItem.image : lineItem.image
  )

  return {
    id: extractNumericFromGID(lineItem.productId, 'Product'),
    variant: extractNumericFromGID(lineItem.variantId, 'ProductVariant'),
    price: lineItem.price,
    brand: 'OWYN',
    quantity: lineItem.quantity,
    name: lineItem.title,
    sku: lineItem.sku,
    image_url: imageUrl,
  }
}

export {
  fireAnalyticsEvent,
  fireElevarAnalyticsEvent,
  fireElevarAnalyticsEventGeneric,
  fireCartUpdatedEvent,
  analyticsPayload,
  setCustomerEmail,
  mapCartLineItemToEcommerceProduct,
}
